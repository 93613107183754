import { Col, Link, NextUIProvider } from "@nextui-org/react";

import {
  Container,
  Card,
  Row,
  Button,
  Spacer,
  Text,
  Switch,
  Grid,
  Image,
  Modal,
  Collapse,
  Progress,
  Loading,
  FormElement,
  Input,
  createTheme,
  useTheme,
  useInput,
} from "@nextui-org/react";

import React, { createContext, useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useAsync } from "react-async";

// import MockPlakTiers from "./mock-plak-tiers";

import parse from "html-react-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faCoins, faCopy } from "@fortawesome/free-solid-svg-icons";

import useSWR from "swr";

import md5 from 'js-md5';

import { useTranslation, Trans } from 'react-i18next';

import { useMediaQuery } from 'usehooks-ts';
import PayLogos from "./PayLogos";

const fetcher = (...args) => fetch(...args).then(res => res.json());

const fetchPlakContentTiers = async ({ signal }) => {
  const response = await fetch(`/api/content/tiers`, { signal });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

const fetchPlakTierDiscounts = async ({ signal }) => {
  const response = await fetch(`/api/content/discounts/bulk`, { signal });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

const fetchPlakRequestOperation = async ({
  signal,
  profileKey,
  tierKey,
  months,
  couponCode
}) => {
  const response = await fetch(
    `/api/content/operations/begin?profileKey=${profileKey}&tierKey=${tierKey}&months=${months}&couponCode=${couponCode}&source=website`,
    { signal, method: "post" }
  );
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

const fetchPlakEndOperation = async ({ signal, profileKey, operationCode }) => {
  const response = await fetch(
    `/api/content/operations/${operationCode}/end?profileKey=${profileKey}`,
    { signal, method: "post" }
  );
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

const ContentTierList = ({ children }) => {
  const state = useAsync({ promiseFn: fetchPlakContentTiers });
  return children(state);
};

const TierBulkDiscountList = ({ children }) => {
  const state = useAsync({ promiseFn: fetchPlakTierDiscounts });
  return children(state);
};

const PlakRequestOperation = ({ children, profileKey, tierKey, months, couponCode }) => {
  const state = useAsync({
    promiseFn: fetchPlakRequestOperation,
    profileKey,
    tierKey,
    months,
    couponCode
  });
  return children(state);
};

const PlakEndOperation = ({ children, profileKey, operationCode }) => {
  const state = useAsync({
    promiseFn: fetchPlakEndOperation,
    profileKey,
    operationCode,
  });
  return children(state);
};

function getPsychologicalPrice(price) {
  return price - 0.01;
}

function getDiscountedPriceNonPsychological(tier, discounts) {
  const price = tier.price_monthly_usd;
  var totalDiscount = 0;

  for (const discount of discounts) totalDiscount += discount.discountPercents;

  return price * (1.0 - totalDiscount / 100);
}

function getDiscountedPrice(tier, discounts) {
  return getPsychologicalPrice(getDiscountedPriceNonPsychological(tier, discounts));
}

function getDiscountPriceTag(original, discounted) {
  if (original == discounted) return <>${original.toFixed(2)}</>;
  else
    return (
      <>
        <Text b color="#444444">
          <strike>${original.toFixed(2)}</strike>
        </Text>{" "}
        <b>${discounted.toFixed(2)}</b>
      </>
    );
}

function getVisibleDiscountPriceTag(tier, discounts) {
  const original = getPsychologicalPrice(tier.price_monthly_usd);
  const discounted = getDiscountedPrice(tier, discounts);

  return getDiscountPriceTag(original, discounted);
}

function EditionCardGrid({ children }) {
  return (
    <Grid xs={12} sm={6} md={3} xl={3}>
      {children}
    </Grid>
  )
}

const ShopContext = createContext({});

function useInputNamed(initialValue) {
  const { value, reset, bindings } = useInput(initialValue);
  return [value, reset, bindings];
}

function PlakTosNotice({ reason }) {  
  return (
    <span style={{ color: '#777', alignContent: 'baseline', marginRight: '0.5rem', fontSize: '0.75rem', letterSpacing: 'initial', marginTop: '0.3em' }}>
      <Trans i18nKey="legal.tos_notice">
        By <span>{{ reason }}</span>, you accept the
        {" "}
        <a style={{ color: '#777', fontWeight: 'bold', textDecoration: 'underline' }} href="https://go.plakmp.com/terms">Plak Graphics Terms of Service</a>.
      </Trans>
    </span>
  );
}

function useSWRSimple(url, dataName) {
  const { data, error, loading } = useSWR(url, fetcher);

  if (error) {
    return [null, (
      <Text b color="error">
        Failed to load {dataName}: {error.toString()}
      </Text>
    )];
  }

  if (loading) {
    return [null, (
      <Loading color="error">Loading {dataName}...</Loading>
    )];
  }

  return [data, null];
}



function useBreakpoint() {
  const sm = useMediaQuery('(min-width: 650px)');
  const md = useMediaQuery('(min-width: 960px)');
  const lg = useMediaQuery('(min-width: 1280px)');
  const xl = useMediaQuery('(min-width: 1400px)');

  if (xl)
    return 'xl';
  else if (lg)
    return 'lg';
  else if (md)
    return 'md';
  else if (sm)
    return 'sm';
  else
    return 'xs';
}

function getBreakpoint(breakpoint, data) {
  if (breakpoint == 'xl') {
    return data['xl'] ?? data['lg'] ?? data['md'] ?? data['sm'] ?? data['xs'] ?? data['default'];
  }
  else if (breakpoint == 'lg') {
    return data['lg'] ?? data['md'] ?? data['sm'] ?? data['xs'] ?? data['default'];
  }
  else if (breakpoint == 'md') {
    return data['md'] ?? data['sm'] ?? data['xs'] ?? data['default'];
  }
  else if (breakpoint == 'sm') {
    return data['sm'] ?? data['xs'] ?? data['default'];
  }
  else if (breakpoint == 'xs') {
    return data['xs'] ?? data['default'];
  }
  else if (breakpoint == 'default') {
    return data['default'];
  }
}

function getBreakpointSize(breakpoint, size) {
  if (size == 'xl') {
    return getBreakpoint(breakpoint, {
      xs: '100%',
      xl: '1400px'
    });
  }
  else if (size == 'lg') {
    return getBreakpoint(breakpoint, {
      xs: '100%',
      lg: '1280px',
      xl: '1400px'
    });
  }
  else if (size == 'md' || size == 'sm') {
    return getBreakpoint(breakpoint, {
      xs: '100%',
      md: '960px',
      lg: '1280px',
      xl: '1400px'
    });
  }
  else if (size == 'xs' || size == 'default') {
    return getBreakpoint(breakpoint, {
      xs: '100%',
      sm: '650px',
      md: '960px',
      lg: '1280px',
      xl: '1400px'
    });
  }
}

function useSizer() {
  const breakpoint = useBreakpoint();

  return {
    breakpoint: breakpoint,
    calc: (data) => getBreakpoint(breakpoint, data),
    size: (size) => getBreakpointSize(breakpoint, size)
  };
}

function EditionCard({ tier, handleCheckout, initialKeyValue }) {
  const { t } = useTranslation();

  const { coupons, discounts, initialCoupon } = useContext(ShopContext);

  const id = tier.tier_key;
  const name = tier.ui_name;
  const description = tier.ui_description;

  const initialPrice = getDiscountedPriceNonPsychological(tier, discounts);

  const [price, setPrice] = React.useState(initialPrice);

  const [visible, setVisible] = React.useState(false);
  const handler = () => setVisible(true);

  const [bigImgVisible, setBigImgVisible] = React.useState(false);
  const [bigImgIndex, setBigImgIndex] = React.useState(0);

  const [checkoutVisible, setCheckoutVisible] = React.useState(false);

  const numScreenshotsPerTier = 3;

  const jsxDescription = parse(description);

  const [value, reset, bindings] = useInputNamed(initialKeyValue);
  const [purchased, setPurchased] = useState(false);

  const closeHandler = () => {
    setVisible(false);
    reset();
    // console.log("closed");
  };

  const helper = React.useMemo(() => {
    const isValid = value.length === 64;
    return {
      text: isValid
        ? t("edition.key.valid")
        : t("edition.key.invalid"),
      color: isValid ? "success" : "error",
      valid: isValid,
    };
  }, [value]);

  const [coupon, resetCoupon, couponBindings] = useInputNamed(initialCoupon);

  const [couponDiscount, setCouponDiscount] = useState(0);

  const getCouponPrice = () => {
    const couponMD5 = md5("PLAK_DISCOUNT_CODE-" + coupon.trim().toUpperCase());
    const isValid = coupons.hasOwnProperty(couponMD5);

    if (isValid) {
      const discount = coupons[couponMD5];
      return initialPrice * (1.0 - discount / 100);
    }
    else {
      return initialPrice;
    }
  };

  const couponHelper = React.useMemo(() => {
    const isPending = false;

    if (isPending || coupon == '') {
      return {
        text: t('edition.coupon.hint'),
        color: null,
        valid: false,
        pending: true
      };
    }
    else {
      const couponMD5 = md5("PLAK_DISCOUNT_CODE-" + coupon.trim().toUpperCase());
      const isValid = coupons.hasOwnProperty(couponMD5);

      return {
        text: isValid
          ? t('edition.coupon.applied', { amount: coupons[couponMD5] })
          : t('edition.coupon.invalid'),
        color: isValid ? "success" : "error",
        valid: isValid,
        pending: false
      };
    }
  }, [coupon]);

  const sizer = useSizer();

  return (
    <EditionCardGrid>
      <Card hoverable clickable onClick={handler}>
        <Image
          showSkeleton
          width="100%"
          src={`https://static.plakmp.com/media/tier-logos/${id}.png`}
          alt="Default Image"
          objectFit="cover"
        />
        <Text h4>{name}</Text>

        <Spacer y={0.1} />

        <Text color="success" b>
          {getVisibleDiscountPriceTag(tier, discounts)} / {t('edition.lifetime')}
        </Text>

        <Spacer y={0.6} />

        <Text>{jsxDescription}</Text>

        <Spacer y={0.2} />

        <Spacer y={2.5} />

        <Button
          auto
          flat
          color="primary"
          css={{
            width: "86.9%",
            height: "2.5em",
            position: "absolute",
            bottom: "1.2em",
          }}
        >
          {t('edition.select')}
        </Button>
      </Card>

      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
        width={sizer.calc({
          xs: '100%',
          lg: '975px',
        })}
        display={{ xs: "none", sm: "block" }}
      >
        <Modal.Header>
          <Text b id="modal-title" size={18}>
            <b>Plak Graphics {name}</b>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Collapse.Group accordion={false}>
            <Collapse
              title={t('edition.features')}
              expanded={false}
              disabled={false}
            >
              <Text blockquote>{jsxDescription}</Text>
            </Collapse>

            <Collapse
              title={t('edition.screenshots')}
              expanded={false}
              disabled={false}
            >
              <Row>
                {[1, 2, 3].map((value) => {
                  return (
                    <>
                      <Image
                        showSkeleton
                        src={`https://static.plakmp.com/media/tier-showcases/${id}/${value}.png`}
                        alt="First slide"
                        onClick={() => {
                          setBigImgIndex(value);
                          setBigImgVisible(true);
                        }}
                        css={{
                          cursor: "pointer",
                        }}
                      />
                      <Spacer />
                    </>
                  );
                })}
              </Row>
            </Collapse>
          </Collapse.Group>

          <Spacer y={0} />
          <Input
            {...bindings}
            clearable
            onClearClick={reset}
            status={helper.color}
            color={helper.color}
            helperColor={helper}
            labelPlaceholder={t('edition.key.placeholder')}
            bordered
            helperText={helper.text}
            initialValue=""
          />
          <Spacer y={0.5} />
          <Spacer y={0} />

          <Input
            {...couponBindings}
            clearable
            onClearClick={resetCoupon}
            status={couponHelper.color}
            color={couponHelper.color}
            helperColor={couponHelper}
            labelPlaceholder={t('edition.coupon.placeholder')}
            bordered
            helperText={couponHelper.text}
            initialValue=""
            width="100%"
          />

          <Spacer y={1} />

          <Text b>
            <Trans i18nKey="edition.access_forever">
              You will get access to Plak Graphics {{ name }} FOREVER.
            </Trans>
          </Text>

          <PlakTosNotice reason={t('tos.creating_order')} />
        </Modal.Body>
        <Modal.Footer>
          <div style={{ marginRight: "auto", marginBottom: '0.25rem' }}>
            <PayLogos bw={false} />
          </div>

          <Button auto flat color="error" onClick={closeHandler} style={{ marginBottom: '0.25rem' }}>
            {t('common.close')}
          </Button>
          <Button
            auto
            shadow={helper.valid}
            color="success"
            disabled={!helper.valid || (!couponHelper.pending && !couponHelper.valid)}
            onClick={() => {
              setCheckoutVisible(true);
            }}
            style={{ marginBottom: '0.25rem' }}
          >
            <b>
              {t('edition.buy_for')}{" "}
              {getDiscountPriceTag(getPsychologicalPrice(initialPrice), getPsychologicalPrice(getCouponPrice()))}
            </b>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        closeButton
        width={sizer.calc({
          xs: '100%',
          lg: '1280px',
        })}
        aria-labelledby="modal-title"
        open={bigImgVisible}
        onClose={() => {
          setBigImgVisible(false);
        }}
      >
        <Modal.Header>
          <Text b id="modal-title" size={18}>
            {t('edition.screenshot_n')} {bigImgIndex}/{numScreenshotsPerTier}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Image
            showSkeleton
            width="100%"
            height="100%"
            src={`https://static.plakmp.com/media/tier-showcases/${id}/${bigImgIndex}.png`}
            alt="First slide"
          />
        </Modal.Body>
        <Modal.Footer justify="center">
          <Button
            color="gradient"
            disabled={!(bigImgIndex > 1)}
            onClick={() => {
              setBigImgIndex(bigImgIndex - 1);
            }}
          >
            {t('edition.screenshot.prev')}
          </Button>

          <Button
            color="gradient"
            disabled={!(bigImgIndex < numScreenshotsPerTier)}
            onClick={() => {
              setBigImgIndex(bigImgIndex + 1);
            }}
          >
            {t('edition.screenshot.next')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        width={sizer.calc({
          xs: '100%',
          lg: '675px',
        })}
        aria-labelledby="modal-title"
        open={checkoutVisible}
        onClose={() => {
          setCheckoutVisible(false);
        }}
      >
        <Modal.Header>
          <Text b id="modal-title" size={18}>
            {t('edition.purchase.title')}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Text>
            <Trans i18nKey="edition.purchase.body">
              Are you sure you want to buy <b>Plak Graphics {{ name }}</b> on key{" "}
              <code>{{ value }}</code> for
            </Trans>
            {" "}
            <b>${getPsychologicalPrice(getCouponPrice()).toFixed(2)}</b>?
            <Spacer y={0} />
            <PlakTosNotice reason={t('tos.creating_order')} />
          </Text>
        </Modal.Body>
        <Modal.Footer justify="center">
          <Button
            color="error"
            onClick={() => {
              setCheckoutVisible(false);
            }}
          >
            {t('common.close')}
          </Button>

          <Button
            color="success"
            shadow
            onClick={() => {
              setPurchased(true);
            }}
          >
            {t('common.confirm')}
          </Button>

          {purchased && (
            <PlakRequestOperation
              profileKey={value}
              tierKey={tier.tier_key}
              months={1}
              couponCode={coupon}
            >
              {({ isPending, data, error }) => {
                if (isPending)
                  return (
                    <Loading color="error">
                      Creating purchase operation...
                    </Loading>
                  );

                if (error) {
                  setPurchased(false);
                  return (
                    <Text b color="error">
                      Failed to create purchase operation: {error.toString()}
                    </Text>
                  );
                }

                if (data) {
                  setPurchased(false);
                  setCheckoutVisible(false);
                  setBigImgVisible(false);
                  setVisible(false);
                  handleCheckout(data);
                }

                return null;
              }}
            </PlakRequestOperation>
          )}
        </Modal.Footer>
      </Modal>
    </EditionCardGrid>
  );
}

function TierShopSelection({
  handleCheckout,
  initialKeyValue,
}) {
  const { t } = useTranslation();

  const { tiers, discounts } = useContext(ShopContext);

  const [showDownloadInfo, setShowDownloadInfo] = useState(false);

  const clientDownloadLink =
    "https://go.plakmp.com/configurator";

  const sizer = useSizer();

  return (
    <Grid.Container
      gap={2}
      justify="center"
      css={{
        display: "flex",
      }}
      sm={11}
    >
      <Grid sm={8}>
        <Card
          color="primary"
          hoverable
          clickable
          onClick={() => {
            setShowDownloadInfo(true);
          }}
        >
          <center>
            <Text b color="#604B40">
              <FontAwesomeIcon
                icon={faDownload}
                style={{
                  paddingRight: "0.5em",
                }}
              />
              {t('home.download')}
            </Text>
          </center>
        </Card>
      </Grid>

      <Grid sm={4}>
        <Card
          hoverable
          clickable
          onClick={() => {
            window.open("https://discord.gg/2wY2aEgU7K", "_blank");
          }}
          css={{
            backgroundColor: "#5865F2",
          }}
        >
          <center>
            <Text
              b
              css={{
                textDecoration: "underline",
                textUnderlinePosition: "under",
              }}
            >
              <Link
                color="text"
                href="https://go.plakmp.com/discord"
                target="_blank"
              >
                {t('home.support')}
              </Link>
            </Text>
          </center>
        </Card>
      </Grid>

      <EditionCardGrid>
        <Card
          hoverable
          clickable
          onClick={() => {
            setShowDownloadInfo(true);
          }}
        >
          <Image
            showSkeleton
            width="100%"
            src={`https://static.plakmp.com/media/tier-logos/launcher.png`}
            alt="Default Image"
            objectFit="cover"
            style={{
              backgroundColor: "#0e0e0e",
            }}
          />
          <Text h4>{t('shop.configurator.title')}</Text>

          <Spacer y={0.1} />

          <Text color="success" b>
            {t('shop.configurator.price')}
          </Text>

          <Spacer y={0.6} />

          <Text>
            <Trans i18nKey="shop.configurator.description1">
              You need Plak Graphics Configurator in order to get your access key,
              use <b>Trial Edition</b> and purchase paid editions.
            </Trans>
          </Text>

          <Spacer y={0.2} />

          <Text>
            <Trans i18nKey="shop.configurator.description2">
              Plak Graphics Configurator is FREE and does not require any special
              setup: all you need to do is follow the simple installation steps.
            </Trans>
          </Text>

          <Spacer y={0.2} />

          <Spacer y={2.5} />

          <Button
            auto
            flat
            color="success"
            css={{
              width: "86.9%",
              height: "2.5em",
              position: "absolute",
              bottom: "1.2em",
            }}
          >
            {t('shop.configurator.open')}
          </Button>
        </Card>
      </EditionCardGrid>

      {tiers.map((tier) => (
        <EditionCard
          key={tier.tier_key}
          tier={tier}
          handleCheckout={handleCheckout}
          initialKeyValue={initialKeyValue}
        />
      ))}

      <Modal
        open={showDownloadInfo}
        onClose={() => {
          setShowDownloadInfo(false);
        }}
        width={sizer.calc({
          xs: '100%',
          lg: '770px',
        })}
      >
        <Modal.Header>
          <Text b id="modal-title" size={18}>
            {t('download.title')}
          </Text>
        </Modal.Header>

        <Modal.Body>
          <Text b>
            {t('download.description.1')}
          </Text>

          <Spacer y={0} />

          <Text>
            {t('download.description.2')}
          </Text>

          <Spacer y={0} />

          <Text b>
            <b>
              <Trans i18nKey='download.instructions'>
                Unpack the following archive into the NFS Most Wanted folder and
                run <code>PlakGraphicsConfigurator.exe</code> <b>AS ADMIN</b>:
              </Trans>
            </b>
          </Text>

          <Spacer y={0} />

          <center>
            <Button
              color="success"
              shadow
              auto
              onClick={() => {
                window.open(clientDownloadLink, "_blank");
              }}
            >
              <b>{t('download.button')}</b>
            </Button>
          </center>

          <Spacer y={-0.5} />

          <Text b>
            <b>
              {t('download.description.3')}
            </b>
          </Text>

          <Spacer y={-0.25} />

          <Text i>
            {t('download.footer')}
          </Text>

          <div style={{ marginTop: '0.75rem', marginBottom: '-0.75rem' }}>
            <PlakTosNotice reason="downloading Plak Graphics" />
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </Grid.Container>
  );
}

function PendingActivationModal({
  show,
  handleClose,
  operation,
  paymentServiceUrl,
  onUpdateOperationData,
}) {
  const { t } = useTranslation();

  let [confirmingCancel, setConfirmingCancel] = useState(false);
  let [requestingEnd, setRequestingEnd] = useState(false);

  const cancelConfirmation = () => {
    setConfirmingCancel(false);
  };

  const centsLeft = operation.requiredSumCents - operation.currentSumCents;
  const progress =
    (operation.currentSumCents / operation.requiredSumCents) * 100;

  const code = operation.operationCode;
  const key = operation.profileKey;
  const showMe = show;
  const updateOperationDataFunc = onUpdateOperationData;

  React.useEffect(() => {
    async function checkFinishStatus() {
      if (!showMe) return;

      try {
        const response = await fetch(
          `/api/content/operations/${code}?profileKey=${key}`
        );
        if (!response.ok) throw new Error(response.statusText);
        const operation = await response.json();

        console.log(JSON.stringify(operation));
        updateOperationDataFunc(operation);

        // if (operation.finished)
        //    alert("finished!");
      } catch (e) {
        // Do nothing lol
        console.log("Caught error updating: " + e.toString());
      }
    }

    const intervalId = setInterval(() => {
      checkFinishStatus();
    }, 1000);

    return () => clearInterval(intervalId);
  });

  React.useEffect(() => {
    if (showMe)
      window.open(operation.paymentDestination, '_blank');
  }, [show, operation.operationCode, operation.paymentDestination]);

  const sizer = useSizer();

  return (
    <>
      <Modal
        blur
        preventClose
        open={!confirmingCancel && show}
        onClose={() => setConfirmingCancel(true)}
        width={sizer.calc({
          xs: '100%',
          lg: '585px',
        })}
      >
        <Spacer />
        <Modal.Header>
          <Text b id="modal-title" size={28}>
            {t('activation.pending.title')}: ${centsLeft / 100}
          </Text>
        </Modal.Header>
        <Modal.Body>

          <center>
            <Text h4>{t('activation.pending.visit_plak_pay')}</Text>
            <Spacer y={-0.25} />
            <Text style={{
              alignItems: 'flex-start'
            }}>
              {t('activation.pending.support_code')}: <code>{operation.operationCode}</code>
            </Text>

            <Spacer y={1} />

            {/*
            <Spacer y={-0.5} />
            <Text>
              <code>{operation.operationCode}</code>
            </Text>
            <Spacer y={-0.25} />
            <Button
              color="secondary"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(operation.operationCode);
              }}
            >
              <FontAwesomeIcon
                icon={faCopy}
                style={{
                  paddingRight: "0.5em",
                }}
              />
              <b>Copy</b>
            </Button>
            <Spacer y={1} />

            <Text h5>
              MAKE SURE THE CODE IS THE <i>ONLY</i> THING IN THE MESSAGE FIELD.{" "}
              <br />
              WE ARE NOT RESPONSIBLE FOR ANY LOSS OF MONEY IF YOU DON'T!
            </Text>*/
            }
          </center>

          <Spacer y={-1} />

          <Progress
            striped
            shadow
            value={operation.currentSumCents}
            max={operation.requiredSumCents}
            color="success"
            status="success"
          />
        </Modal.Body>

        <Modal.Footer justify="center">
          <Button
            auto
            color="secondary"
            shadow
            onClick={() => {
              window.open(operation.paymentDestination, "_blank");
            }}
          >
            <FontAwesomeIcon
              icon={faCoins}
              style={{
                paddingRight: "0.5em",
              }}
            />
            {t('activation.pending.open_plak_pay')}
          </Button>

          <Spacer x={0} y={0} />

          <Button auto color="error" onClick={() => setConfirmingCancel(true)}>
            {t('activation.pending.cancel_activation')}
          </Button>
        </Modal.Footer>

        <Spacer y={0.1} />

        <Text h6 b css={{ textDecoration: "underline", textUnderlinePosition: "under" }}>
          <Link
            color="text"
            href="https://go.plakmp.com/discord"
            target="_blank"
          >
            <Trans i18nKey="activation.pending.having_trouble">
              Having trouble? Contact&nbsp;
              <Text b color="#00AAFF">
                @Plak Graphics Staff
              </Text>
              &nbsp;in our Discord with your code!
            </Trans>
          </Link>
        </Text>

        <Spacer y={1} />
      </Modal>

      <Modal blur open={show && confirmingCancel} onClose={cancelConfirmation}>
        <Spacer />
        <Modal.Header>
          <Text b id="modal-title" size={28}>
            {t('activation.pending.confirm_cancel')}
          </Text>
        </Modal.Header>

        <Modal.Body>
          <b>ARE YOU SURE YOU WANT TO CANCEL YOUR ACTIVATION?</b>
          <br />
          ANY MONEY ALREADY PAID WILL BE LOST!
          {requestingEnd && (
            <PlakEndOperation
              profileKey={operation.profileKey}
              operationCode={operation.operationCode}
            >
              {({ isPending, data, error }) => {
                if (isPending)
                  return (
                    <Loading color="error">
                      Ending purchase operation...
                    </Loading>
                  );

                if (error)
                  return (
                    <Text b color="error">
                      Failed to end purchase operation: {error.toString()}
                    </Text>
                  );

                if (data) {
                  setRequestingEnd(false);
                  setConfirmingCancel(false);
                  handleClose();
                }

                return null;
              }}
            </PlakEndOperation>
          )}
        </Modal.Body>

        <Modal.Footer justify="center">
          <Button color="success" auto onClick={cancelConfirmation}>
            {t('common.no')}
          </Button>
          <Button color="error" shadow onClick={() => setRequestingEnd(true)}>
            {t('activation.pending.cancel_activation')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function SuccessfulActivationModal({ show, handleClose, operation }) {
  const { t } = useTranslation();

  let [requestingEnd, setRequestingEnd] = useState(false);

  return (
    <Modal blur open={show} onClose={handleClose}>
      <Spacer />
      <Modal.Header>
        <Text b id="modal-title" size={28}>
          {t('activation.success.header')}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Text>
          <Trans i18nKey={'activation.success.body'}>
            Thank you for purchasing <b>Plak Graphics</b>! The tier you purchased
            will now be available in your game.
          </Trans>
        </Text>

        {requestingEnd && (
          <PlakEndOperation
            profileKey={operation.profileKey}
            operationCode={operation.operationCode}
          >
            {({ isPending, data, error }) => {
              if (isPending)
                return (
                  <Loading color="error">{t('misc.ending_operation')}</Loading>
                );

              if (error)
                return (
                  <Text b color="error">
                    {t('error.failed_to_end_operation')} {error.toString()}
                  </Text>
                );

              if (data) {
                setRequestingEnd(false);
                handleClose();
              }

              return null;
            }}
          </PlakEndOperation>
        )}
      </Modal.Body>
      <Modal.Footer>
        <center>
          <Button color="success" auto onClick={() => setRequestingEnd(true)}>
            {t('common.close')}
          </Button>
        </center>
      </Modal.Footer>
    </Modal>
  );
}

function GlobalPromoModal({ show, handleClose, promo }) {
  const { t } = useTranslation();

  const sizer = useSizer();

  if (!promo.active) return <></>;

  const jsxMessage = parse(promo.message);

  return (
    <Modal
      width={sizer.calc({
        xs: '100%',
        md: '600px',
        lg: '700px',
      })}
      open={show}
      onClose={() => handleClose(false)}
      style={{ backgroundColor: '#050505', borderRadius: '0.5rem' }}
    >
      <Spacer y={0.55} />
      <Modal.Body>
        <Image
          showSkeleton
          src={promo.promoImageLink}
          width="100%"
          style={{ borderRadius: '0.5rem' }}
        />

        <center>
          {promo.i18nKey ? <Trans i18nKey={promo.i18nKey}>{jsxMessage}</Trans> : jsxMessage}
          <Spacer y={0.25} />
          <a onClick={() => handleClose(true)} style={{ fontSize: 14, color: "#FFFFFF88", textDecoration: 'underline' }}>
            {t('promo.dont_show_again')}
          </a>
        </center>
      </Modal.Body>
      <Spacer y={0.25} />
    </Modal>
  );
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function TierShop({ session }) {
  const { i18n } = useTranslation();

  let { key } = useParams();
  const hasAutoKey = key !== undefined;

  const query = useQuery();

  let [showDownloadModal, setShowDownloadModal] = useState(false);
  let [showObservingModal, setShowObservingModal] = useState(false);
  let [observingTier, setObservingTier] = useState({});
  let [showPendingPurchase, setShowPendingPurchase] = useState(false);
  let [pendingPurchaseOperation, setPendingPurchaseOperation] = useState({});
  let [showSuccessfulPurchase, setShowSuccessfulPurchase] = useState(false);
  let [initialPendingShown, setInitialPendingShown] = useState(false);
  let [showGlobalPromo, setShowGlobalPromo] = useState(true);

  const closeDownloadModal = () => {
    setShowDownloadModal(false);
  };

  const closeObservingModal = () => {
    setShowObservingModal(false);
    // setObservingTier({});
  };

  const showPurchasePendingScreen = (operation) => {
    setShowDownloadModal(false);
    setShowObservingModal(false);
    setShowPendingPurchase(true);
    setShowSuccessfulPurchase(false);
    setPendingPurchaseOperation(operation);
  };

  const onUpdateOperationData = (operation) => {
    setPendingPurchaseOperation(operation);

    if (operation.finished) {
      console.log('Operation finished!')

      setShowPendingPurchase(false);
      setShowSuccessfulPurchase(true);
    }
  };

  if (session.pending && !session.operation.finished && !initialPendingShown) {
    setInitialPendingShown(true);
    showPurchasePendingScreen(session.operation);
  }

  const [discounts, discountsPending] = useSWRSimple('/api/content/discounts/bulk', 'discounts');
  const [coupons, couponsPending] = useSWRSimple('/api/content/discounts/coupons', 'coupons');
  const [tiers, tiersPending] = useSWRSimple('/api/content/tiers?locale=' + i18n.resolvedLanguage, 'editions');
  const [promo, promoPending] = useSWRSimple('/api/content/promo/current', 'promo info');

  if (!discounts)
    return discountsPending;

  if (!coupons)
    return couponsPending;

  if (!tiers)
    return tiersPending;

  if (!promo)
    return promoPending;

  console.log(discounts);
  console.log(coupons);
  console.log(tiers);
  console.log(promo);

  const shopContext = {
    discounts: discounts,
    coupons: coupons,
    tiers: tiers,
    initialCoupon: query.get('promo') ?? (query.get('coupon') ?? '')
  };

  const PROMO_REFUSAL_ID = 'plak-shop-last-refused-promo-id';

  const lastRefusedPromo = localStorage.getItem(PROMO_REFUSAL_ID) ?? '';

  const isPromoSkipped = (lastRefusedPromo === promo.promoId);

  return (
    <ShopContext.Provider value={shopContext}>
      <Container>
        <PendingActivationModal
          show={showPendingPurchase}
          handleClose={() => {
            setShowPendingPurchase(false);
          }}
          operation={pendingPurchaseOperation}
          paymentServiceUrl={session.paymentServiceUrl}
          onUpdateOperationData={onUpdateOperationData}
        />

        <GlobalPromoModal
          show={promo.active && showGlobalPromo && !isPromoSkipped}
          handleClose={(dontShowAgain) => {
            if (dontShowAgain) {
              localStorage.setItem(PROMO_REFUSAL_ID, promo.promoId);
            }

            setShowGlobalPromo(false);
          }}
          promo={promo}
        />

        {
          <SuccessfulActivationModal
            show={showSuccessfulPurchase}
            handleClose={() => {
              setShowSuccessfulPurchase(false);
            }}
            operation={pendingPurchaseOperation}
          />
        }

        <Spacer y={0.75} />

        <Row justify="center" align="center">
          <TierShopSelection
            openDetailsModal={(tier) => {
              //setShowObservingModal(true);
              //setObservingTier(tier);
            }}
            handleCheckout={showPurchasePendingScreen}
            initialKeyValue={hasAutoKey ? key : ""}
          />
        </Row>
      </Container>
    </ShopContext.Provider>
  );
}