export default function PayLogos({ bw }) {
    const suffix = bw ? '_bw.svg' : '.svg';
    const prefix = '/media/paylogos/paylogo_';

    const makePayLogoUrl = (id) => prefix + id + suffix;

    return (
        <div style={{
            display: 'grid',
            gridAutoFlow: 'column',
            gridColumnGap: '15px'
        }}
        >
            <img height="50px" src={makePayLogoUrl('mastercard')} />
            <img height="50px" src={makePayLogoUrl('visa')} />
            <img height="50px" src={makePayLogoUrl('mir')} />
            <img height="50px" src={makePayLogoUrl('paypal')} />
        </div>
    );
}